import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
export var getLanguage = function getLanguage(className) {
  var match = /language-(\w*)/.exec(className || 'language-javascript');
  var lang = 'javascript';

  if (match && match.length > 1) {
    lang = match[1];
  }

  return lang;
};
export var pre = function pre(props) {
  return props.children;
};
export var code = function code(props) {
  var language = getLanguage(props.className);
  return React.createElement(SyntaxHighlighter, Object.assign({
    language: language
  }, props));
};
export default {
  components: {
    pre: pre,
    code: code
  }
};