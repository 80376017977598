
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { themes, Head } from 'mdx-deck'
import Box from './Box.js'
import GatsbyLogo from './images/gatsby.svg'
import ContentfulLogo from './images/contentful.png'
export const theme = { ...themes.yellow,
  ...themes.prism
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head mdxType="Head">
  <title>Build a blog with Contentful and Gatsby</title>
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@smakosh" />
  <meta name="twitter:title" content="Build a blog with Contentful and Gatsby" />
  <meta name="twitter:description" content="A talk by Smakosh" />
  <meta name="twitter:image" content="https://2020.css-grids-talk.talks.smakosh.com/images/cover.png" />
    </Head>

    <h1>{`Build a blog with Contentful and Gatsby`}</h1>
    <h3>{`A talk by `}<a parentName="h3" {...{
        "href": "https://smakosh.com"
      }}>{`Smakosh`}</a></h3>
    <hr></hr>
    <h2>{`Who's Smakosh? on one hand`}</h2>
    <p>{`Smakosh LLC is a one man software company that crafts software for startups using JavaScript tools like:`}</p>
    <ul>
      <li parentName="ul">{`Gatsby js`}</li>
      <li parentName="ul">{`Next js`}</li>
      <li parentName="ul">{`React Native`}</li>
      <li parentName="ul">{`etc...`}</li>
    </ul>
    <hr></hr>
    <h2>{`On the other hand`}</h2>
    <p>{`I'm Ismail Ghallou`}</p>
    <ul>
      <li parentName="ul">{`A self-taught JavaScript developer`}</li>
      <li parentName="ul">{`I work as a Full stack JavaScript developer at Maltem Africa`}</li>
      <li parentName="ul">{`Speaker`}</li>
      <li parentName="ul">{`Blogger`}</li>
      <li parentName="ul">{`Maker of `}<a parentName="li" {...{
          "href": "https://ai-hashtags.com"
        }}>{`Ai Hashtags`}</a>{` and other `}<a parentName="li" {...{
          "href": "https://github.com/smakosh"
        }}>{`open source tools`}</a></li>
    </ul>
    <hr></hr>
    <h2>{`What this talk is about?`}</h2>
    <ul>
      <li parentName="ul">{`What's Headless CMS? and why it's way better than traditional CMS`}</li>
      <li parentName="ul">{`Micro introduction to Gatsby js`}</li>
      <li parentName="ul">{`The JAMStack, the What/Why/How?`}</li>
      <li parentName="ul">{`Introduction to Contentful`}</li>
      <li parentName="ul">{`Building a blog using Contentful and Gatsby`}</li>
    </ul>
    <hr></hr>
    <h2>{`What this talk isn't about?`}</h2>
    <ul>
      <li parentName="ul">{`Fundamentals and basics of GraphQL`}</li>
      <li parentName="ul">{`Feel free to use the CMS that fits your use case`}</li>
      <li parentName="ul">{`Fundamentals and basics of React js`}</li>
    </ul>
    <hr></hr>
    <h2>{`What's Headless CMS?`}</h2>
    <hr></hr>
    <p>{`Before I answer this question, let's see how "Traditional CMS" works`}</p>
    <p><a parentName="p" {...{
        "href": "https://thewaltdisneycompany.com/"
      }}>{`Walt Disney Company`}</a></p>
    <hr></hr>
    <p>{`Now let's see an example of a website built with the JAMStack (Headless CMS + JavaScript)`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.waddle.com.au/"
      }}>{`Waddle`}</a></p>
    <hr></hr>
    <p>{`But if data already exists and is not fetched at run time, then when does it get fetched?`}</p>
    <hr></hr>

    <h2>{`Meet Gatsby js`}</h2>
    <Box mdxType="Box">
  <img src={GatsbyLogo} alt="Gatsby logo" />
    </Box>
    <hr></hr>
    <p>{`A modern web framework built with React, GraphQL and Webpack`}</p>
    <p>{`It is known for it's great SSG feature which lets you do SSR (Server Side rendering) during build time`}</p>
    <hr></hr>
    <p>{`This lets us fetch content from Contentful during build time, create the pages for each content`}</p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`You can build dynamic apps with it and fetch content from Contentful using their API too`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Benefits?`}</h2>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Blazing fast as it's a hydrated SPA (Single Page App)`}</li>
        <li parentName="ul">{`You could turn your website into a PWA in seconds and have content reachable offline`}</li>
        <li parentName="ul">{`SEO friendly as there exists an HTML page that can be crawled by web crawlers`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Modern web crawlers like Google's bot can actually crawl JavaScript, your SPA can be crawled now`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`JAMStack, the what/why/how?`}</h2>
    <p>{`The Jamstack is not about specific technologies. It’s a new way of building websites and apps that delivers:`}</p>
    <ul>
      <li parentName="ul">{`better performance`}</li>
      <li parentName="ul">{`higher security`}</li>
      <li parentName="ul">{`lower cost of scaling`}</li>
      <li parentName="ul">{`and a better developer experience.`}</li>
    </ul>
    <p>{`From `}<a parentName="p" {...{
        "href": "https://jamstack.org"
      }}>{`JAMStack.org`}</a></p>
    <hr></hr>

    <h2>{`Contentful - Distribute Content to any platform`}</h2>
    <Box mdxType="Box">
  <img style={{
        width: 256
      }} src={ContentfulLogo} alt="Contentful logo" />
    </Box>
    <hr></hr>
    <p>{`A modern CMS that acts like a content infrastructure which can be used as a headless CMS`}</p>
    <hr></hr>
    <h2>{`Time to build our JAMStack powered blog`}</h2>
    <hr></hr>
    <h2>{`Thank you!`}</h2>
    <p>{`Feel free to ask me anything`}</p>
    <hr></hr>
    <h2>{`Referrences`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://contentful.com"
        }}>{`https://contentful.com`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jamstack.org"
        }}>{`https://jamstack.org`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://gatsbyjs.org"
        }}>{`https://gatsbyjs.org`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;