import styled from 'styled-components'

export default styled.div`
  max-width: 960px;
  margin: 0 auto;

  img {
    width: 100%;
    height: unset;
  }
`