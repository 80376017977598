import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.assign";
import React from 'react';
import { Prism } from 'react-syntax-highlighter';
import { getLanguage } from './syntax-highlighter';
export var pre = function pre(props) {
  return props.children;
};
export var code = function code(props) {
  var language = getLanguage(props.className);
  return React.createElement(Prism, Object.assign({
    language: language
  }, props));
};
export default {
  components: {
    pre: pre,
    code: code
  }
};